import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { langProps } from "../../components/types"
import Layout from "../../components/Layout"

//const IndexPage: React.FC<PageProps> = () => {
export default (props: langProps) => {
    return (
        <Layout {...props} lang="de" >
            <div className="banner blue" style={{minHeight:'70vh'}}>
                <h1 style={{marginTop: 60}}>Vielen Dank für deine Nachricht</h1>
                <p>Sie wurde erfolgreich übermittelt und wir werden dir so bald wie möglich antworten.</p>
            </div>
        </Layout>
    )
}

//export default IndexPage

// add headers
import Headers from '../../components/Headers'
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => <Headers
    title="Nachricht gesendet"
    noindex
    location={location}
    />
